<template>
  <div class="roomHandle">
    <v-list
      ref="list"
      :isAdd="true"
      :isManySelect="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :selectable="selectable"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <area-select @changeModel="changeModel" />
        <v-input label="用户手机" v-model="searchParam.mobile" />
        <v-input label="用户昵称" v-model="searchParam.nickName" />
        <v-select
          clearable
          :options="sexOptions"
          v-model="searchParam.gender"
          label="性别"
        />
        <v-select
          clearable
          :options="auditStatusOptions"
          v-model="searchParam.auditStatus"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="toEdit(scope.row.id)"
        />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量审核"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        ></v-button>
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="驳回"
      sureTxt="通过"
    >
      <span>是否审核通过?</span>
    </v-dialog>
  </div>
</template>

<script>
import {
  getRoomHandleListUrl,
  passRoomUrl,
  rejectRoomUrl,
} from "./api.js";
import {
  auditStatusOptions,
  auditStatusMap,
  relationOptions,
  relationOptionsMap,
  sexOptions,
  sexOptionsMap,
  userTypeOptionsMap,
  userTypeOptions,
} from "./map";

export default {
  name: "accountList",
  data() {
    return {
      isdialog: false,
      id: "",
      relationOptions,
      sexOptions,
      userTypeOptions,
      auditStatusOptions,
      searchParam: {
        userType: null,
        spaceId: null,
        identity: null,
        mobile: null,
        nickName: null,
        gender: null,
        auditStatus: null,
      },
      extraParams: {},
      tableUrl: getRoomHandleListUrl,
      headers: [
        {
          prop: "mobile",
          label: "用户手机号",
        },
        {
          prop: "nickName",
          label: "用户昵称",
        },
        {
          prop: "gender",
          align: "center",
          label: "性别",
          formatter: (row, prop) => {
            return sexOptionsMap[row.gender];
          },
        },
        {
          prop: "communityName",
          align: "center",
          label: "所在小区",
        },
        {
          prop: "spaceName",
          label: "住址",
        },
        {
          prop: "auditStatus",
          align: "center",
          label: "审核状态",
          formatter: (row, prop) => {
            return auditStatusMap[row.auditStatus];
          },
        },
        {
          prop: "userType",
          align: "center",
          label: "用户类别",
        },
        {
          prop: "identity",
          align: "center",
          label: "与产权人关系",
          formatter: (row, prop) => {
            return relationOptionsMap[row.identity];
          },
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    changeModel(value) {
      // if (value.specifyCollectionTypes == 1) {
      //   this.searchParam.userType = "居民";
      // } else if (value.specifyCollectionTypes == 2) {
      //   this.searchParam.userType = "商户";
      // } else if (value.specifyCollectionTypes == 3) {
      //   this.searchParam.userType = "运营人员";
      // }
      this.searchParam.userType = "";
      this.searchParam.spaceId = value.spaceId;
    },
    selectable(row) {
      if (row.auditStatus != 0) {
        return false;
      }
      return true;
    },
    cancel() {
      this.handleRoomUrl(this.id, 2);
    },
    confirm() {
      this.isdialog = false;
      this.handleRoomUrl(this.id, 1);
    },
    toEdit(id) {
      this.isdialog = true;
      this.id = id;
    },
    handleRoomUrl(ids, auditStatus) {
      let params = {
        ids: ids,
        auditStatus: auditStatus,
        auditUser: this.$store.state.app.userInfo.userId,
      };
      this.$axios
        .post(`${passRoomUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          } else {
          }
        });
    },
    batchLineSend(row, type) {
      let id = row.id;
      this.send(id, type);
    },
    batchSend(list, type) {
      console.log("list--", list);
      const { data, ids } = list;
      let id = ids.join(",");
      //   this.send(id, type);
      this.toEdit(id);
    },
  },
};
</script>
<style lang="less" scoped>
.roomHandle {
  box-sizing: border-box;
  height: 100%;
}
</style>
